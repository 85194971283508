import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import QuickDropImage from "../images/apps/quickdrop.png"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
        <Seo title="Portfolios" />
        <h2>Portfolios</h2>
        <div className="portfolios">
        <p>
            <h3>Quick Drop - Drag, Drop, Done</h3>
            <p>Quickly trigger custom workflows on files using drag&drop gestures</p>
            <a href="https://quickdrop.antran.app/"><img src={QuickDropImage} alt="Quick Drop app icon" width="128" height="128" className="icon"/></a>
        </p>
        </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
